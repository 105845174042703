import React, {useState} from 'react';
import 'antd/dist/antd.css';
import {Form, Tabs} from 'antd';
import {PlusOutlined, SearchOutlined, ClearOutlined, SettingOutlined, FileTextOutlined} from '@ant-design/icons';
import Search from "./Search";
import InsertRecord from "./InsertRecord";
import OtherActions from "./OtherActions";
import {runSearch} from "./api";
import ApiLog from "./ApiLog";
import Configurator from "./Configurator";
import {searchRequest} from "./rmsapi";

const {TabPane} = Tabs;

const tabInnerStyle = {minHeight: 300};


function App() {
    const [records, setRecords] = useState(null);
    const [apiLogItems, setApiLogItems] = useState([]);
    const [form] = Form.useForm();

    async function refreshRecords() {
        setRecords(null);
        const result = await runSearch();
        setRecords(result);
        addToApiLog({
            request: searchRequest, response: JSON.stringify(result, null, '\t')
        });
    }

    async function changeTab(tab) {
        if (tab === "query") {
            await refreshRecords();
        }
    }

    function addToApiLog(item) {
        setApiLogItems(apiLogItems => [{...item, timestamp: new Date()}, ...apiLogItems]);
    }

    function clearLog() {
        setApiLogItems([]);
    }

    return (
        <div className="App">
            <Tabs defaultActiveKey="insert" onChange={changeTab}>
                <TabPane tab={<><PlusOutlined/> Insert a Record</>} key="insert">
                    <div style={tabInnerStyle}>
                        <InsertRecord form={form} addToApiLog={addToApiLog}/>
                    </div>
                </TabPane>
                <TabPane tab={<><SearchOutlined/> Query RMS file</>} key="query">
                    <div style={tabInnerStyle}>
                        <Search records={records} refreshRecords={refreshRecords} addToApiLog={addToApiLog}/>
                    </div>
                </TabPane>
                <TabPane tab={<><ClearOutlined/> Other Actions</>} key="actions">
                    <div style={tabInnerStyle}>
                        <OtherActions addToApiLog={addToApiLog}/>
                    </div>
                </TabPane>
                <TabPane tab={<><SettingOutlined/> Configurator</>} key="config">
                    <div style={tabInnerStyle}>
                       <Configurator/>
                    </div>
                </TabPane>
                <TabPane tab={<><FileTextOutlined/> API Documentation</>} key="openapi">
                    <div style={tabInnerStyle}>
                        <div style={tabInnerStyle}>
                            <p>Our API generates OpenAPI specification, based on the RMS file mapping. This specification
                                can be used to generate client code, mock API server for testing or to generate API documentation.</p>
                            <p>OpenAPI Specification for the demo API is available on <a href="https://demoapi.sgco.com/openapi">https://demoapi.sgco.com/openapi</a>.</p>
                            <p>Swagger UI: <a href="https://sgco-api-documentation.s3.amazonaws.com/index.html">https://sgco-api-documentation.s3.amazonaws.com/index.html</a></p>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
            <ApiLog items={apiLogItems} clearLog={clearLog}/>
        </div>
    );
}

export default App;
