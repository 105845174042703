const postRequest = endpoint => `POST https://apiurl/${endpoint} HTTP/1.1
Content-Type: application/json`

export const searchRequest = `GET https://apiurl/ HTTP/1.1
Content-Type: application/json`;

export const insertRequest = data => `${postRequest('webpfl')} 

${JSON.stringify(data, null, '\t')}`;

export const deleteAllRequest = `DELETE https://apiurl/ HTTP/1.1`;

export const deleteOneRequest = id => `DELETE https://apiurl/webpfl/${id}`;
