import axios from "axios";

const client = axios.create({
    baseURL: 'https://demoapi.sgco.com/'
})

export const runSearch = async () => {
    return client.get("/webpfl").then(({data}) => {
       return (data);
    }).catch(e => console.error(e));
}

export const insertRecord = async data => {
    const params = new URLSearchParams();
    Object.keys(data).forEach(key => {
        params.append(key, data[key]);
    });
    return client.post("/webpfl", data);
}

export const deleteOne = async id => {
    return client.delete(`/webpfl/${id}`);
}

export const deleteAll = async () => {
    return client.delete("/webpfl");
}

export const getDownloadLink = async () => {
    return client.get("/download_link");
}

export const downloadFile = async link => {
    const params = new URLSearchParams();
    params.append("download_link", link);
    return client.post("/downloadFile", params);
}
