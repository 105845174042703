import React from 'react';
import ModalImage from 'react-modal-image';
import {Row, Col} from 'antd';

const images =
    [{
        src: "/images/screenshots/home.png",
        thumbnail: "/images/screenshots/thumbnails/home.png",
        thumbnailCaption: "Home Screen: view the overall status and activity history of the API.",
        caption: "Home Screen"
    }, {
        src: "/images/screenshots/api-settings.png",
        thumbnail: "/images/screenshots/thumbnails/api-settings-thumbnail.png",
        caption: "API Settings",
        thumbnailCaption: 'API settings: define API environments for each API instance. Multiple instances can be run on different ports (dev, stage, prod etc.)',
    }, {
        src: "/images/screenshots/rms-file-list.png",
        thumbnail: "/images/screenshots/thumbnails/rms-file-list.png",
        caption: "RMS File List",
        thumbnailCaption: 'RMS File List Screen: define macro info for the RMS data files to be mapped. This allows real time mapping feedback.',
    }, {
        src: "/images/screenshots/mapping-add.png",
        thumbnail: "/images/screenshots/thumbnails/mapping-add.png",
        caption: "Add New Mapping",
        thumbnailCaption: 'Add New Mapping Screen: this is the main control screen to launch new RMS file mappings. Macro info from the previous screen utilized.',
    }, {
        src: "/images/screenshots/mapping-view.png",
        thumbnail: "/images/screenshots/thumbnails/mapping-view.png",
        caption: "File Mapping",
        thumbnailCaption: 'File Mapping Screen: Populates dynamically as you create mappings.',
    }, {
        src: "/images/screenshots/mapping-live-view.png",
        thumbnail: "/images/screenshots/thumbnails/mapping-live-view.png",
        caption: "Mapping Verification",
        thumbnailCaption: 'Mapping Verification Screen: see real time results of mappings as they are built receiving dynamic feedback as each field is mapped.',
    }, {
        src: "/images/screenshots/mapping-edit.png",
        thumbnail: "/images/screenshots/thumbnails/mapping-edit.png",
        caption: "Edit Mapping",
        thumbnailCaption: 'Edit Mapping Screen: mapping modifications can easily be made at any time.',
    },]

const Configurator = () => {
    return <Row style={{marginBottom: 50}}>
        {images.map((image, idx) => <Col span={12} key={`img-${idx}`} style={{
            textAlign: 'center',
            borderBottom: idx === images.length - 1 ? null : '2px solid #eee'
        }}>
            <p style={{minHeight: 50, paddingTop: 10}}>{image.thumbnailCaption}</p>
            <div style={{minHeight: 350}}>
                <ModalImage
                    small={image.thumbnail}
                    large={image.src}
                    alt={image.caption}
                /></div>
        </Col>)}
        <Col span={12} style={{textAlign: 'center', borderBottom: '2px solid #eee'}}>
            <a href='http://sgco-api-config.vercel.app'><p style={{minHeight: 50, paddingTop: 10, fontSize: 24, fontWeight: 'bold'}}>Try the
                Configurator!</p>
                <div style={{minHeight: 350}}>
                    <img src={images[0].thumbnail}
                         alt={images[0].caption}
                    /></div>
            </a>
        </Col>
    </Row>
}
export default Configurator;
