import React, {useEffect} from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-http.min';
import 'prismjs/components/prism-json.min';
import {DeleteOutlined, InfoCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined} from '@ant-design/icons';
import './prism.css';
import {Divider, Timeline, PageHeader, Button, Tag} from "antd";

const Code = ({contents, language}) => {
    return <pre>
        <code className={`language-${language}`}>
            {contents}
        </code>
    </pre>;
}

export default ({items, clearLog}) => {
    useEffect(() => {
        Prism.highlightAll();
    }, [items]);
    return <div style={{border: "5px solid #eff1f7", padding: 20, borderRadius: 20}}><PageHeader title="API Log" extra={[<Button onClick={() => clearLog()}><DeleteOutlined/> Clear Log</Button>]}/><Timeline>
        {items.length===0 && <p><InfoCircleOutlined/> This panel will populate as you make API requests using the UI above.</p>}
        {items.map((item, key) => <>
            <Timeline.Item color={"grey"}><Divider dashed>{item.timestamp.toLocaleTimeString()} &bull; API Call #{items.length-key}</Divider></Timeline.Item>
            {item.request && <Timeline.Item key={`req-${key}`} color="red">Sending this <Tag color="geekblue">Request <DoubleRightOutlined/></Tag>to the API: <Code contents={item.request} language={"http"}/></Timeline.Item>}
            {item.response && <Timeline.Item key={`res-${key}`} color="green">JSON <Tag color="geekblue"><DoubleLeftOutlined/> Response</Tag><Code contents={item.response} language={"json"}/></Timeline.Item>}
            <Timeline.Item color={"grey"}><Divider dashed>End of the API call</Divider></Timeline.Item>
        </>)}
    </Timeline></div>;
}
