import React from 'react';
import {Button, Popconfirm, Space, Spin, Table} from "antd";
import {deleteOne} from "./api";
import {deleteOneRequest} from "./rmsapi";

export default ({records, refreshRecords, addToApiLog}) => {
    const columns = [
        {
            title: "Accession Number",
            dataIndex: "ACCESSION_NUMBER"
        },
        {
            title: 'First Name',
            dataIndex: 'FIRST_NAME',
        },
        {
            title: 'Last Name',
            dataIndex: 'LAST_NAME',
        },
        {
            title: 'E-mail Address',
            dataIndex: 'EMAIL_ADDRESS',
        },
        {
            title: 'Actions',
            render: record => <Space><Popconfirm title="Are you sure?" disabled={false} onConfirm={() => deleteRecord(record.ACCESSION_NUMBER)} okText="Yes" cancelText="No"><Button type="default">Delete</Button></Popconfirm></Space>
        }
    ];
    async function deleteRecord(accessionID) {
        const {data} = await deleteOne(accessionID.trim());
        addToApiLog({request: deleteOneRequest(accessionID.trim()), response: JSON.stringify(data.message, null, "\t")});
        window.setTimeout(() => refreshRecords(), 1000);
    }
    if (records===null) return <div style={{display: "flex", height: 300, alignItems: "center", justifyContent: "center"}}><Spin size="large"/></div>;
    return <Table columns={columns} dataSource={records}/>
}
