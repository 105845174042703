import React, {useState} from 'react';
import {Button, message, Popconfirm, Space, Spin} from "antd";
import {deleteAll, downloadFile, getDownloadLink} from "./api";
import {deleteAllRequest} from "./rmsapi";


export default ({addToApiLog}) => {
    async function deleteRecords() {
        const {data} = await deleteAll();
        addToApiLog({request: deleteAllRequest, response: JSON.stringify(data.message, null, "\t")});
        alert("All records deleted");
    }
    async function downloadRMS() {
        setIsDownloading(true);
        message.success("Archiving...");
        const {data} = await getDownloadLink();
        message.success("Transferring...");
        const response = await downloadFile(data.download_link);
        const filename = data.download_link.split("/").pop();
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename;
        link.click()
        setIsDownloading(false);
    }

    const [isDownloading, setIsDownloading] = useState(false);
    return <Space>
        <Button onClick={downloadRMS} /*disabled={isDownloading}*/ disabled={true}>{isDownloading ? <Space>Preparing <Spin/></Space> : "Download RMS file"}</Button>
        <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={deleteRecords}>
            <Button>
                Delete All Records
            </Button></Popconfirm>
    </Space>
}
