import React, {useState} from 'react';
import {Button, Form, Input, Space, Spin} from "antd";
import {insertRecord} from "./api";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {insertRequest} from "./rmsapi";

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 3, span: 16 },
};

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const StateIcon = ({submitState}) => {
    if (submitState==="submitting") return <Spin/>;
    if (submitState==="ok") return <Space><CheckCircleFilled style={{color: "green", fontSize: 24}}/>Record Saved</Space>;
    if (submitState==="error") return <Space><CloseCircleFilled style={{color: "red", fontSize: 24}}/>Error while saving the record. Some fields are set as unique, please change your input and try again.</Space>;
    return null;
}

export default ({form, addToApiLog}) => {
    const [submitState, setSubmitState] = useState(null);
    async function insert(values) {
        let timeout = 2000;
        setSubmitState("submitting");
        try {
            const {data} = await insertRecord(values);
            addToApiLog({request: insertRequest(values), response: JSON.stringify(data, null, "\t")});
            setSubmitState("ok");
            form.resetFields();
        } catch (e) {
            setSubmitState("error");
            timeout = 7000;
        }
        window.setTimeout(() => setSubmitState(null), timeout);
    }
    return <Form {...layout} form={form} onFinish={insert}>
        <Form.Item
            label="First Name"
            name="FIRST_NAME"
            rules={[{required: true, message: 'Please input first name'}]}
            >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Last Name"
            name="LAST_NAME"
            rules={[{required: true, message: 'Please input last name'}]}
            >
            <Input/>
        </Form.Item>
        <Form.Item
            label="E-mail address"
            name="EMAIL_ADDRESS"
            rules={[
                {required: true, message: 'Please input e-mail address'},
                {pattern: emailRegex, message: 'That does not look like a valid email.'}
                ]}
            >
            <Input/>
        </Form.Item>
        <Form.Item {...tailLayout}>
            <Space>
                <Button type="primary" htmlType="submit">Save</Button>
                <Button type="dashed" htmlType="reset" onClick={() => form.resetFields()}>Cancel</Button>
            </Space>
        </Form.Item>
        <Form.Item {...tailLayout}>
            <StateIcon submitState={submitState}/>
        </Form.Item>
    </Form>
}
